import React, { useState, useEffect } from 'react'
import sessionUtils from '../../utilities/SessionHandler'

import Navbar from '../../components/Private/Navbar'
import StatisticCard from '../../components/Private/Card/DashboardStatistics'
import NavigationCard from '../../components/Private/Card/DashboardNavigation'

import userService from '../../services/users'
import roleService from '../../services/roles'
import applicationService from '../../services/applications'
import notificationService from '../../services/notifications'

import ModalComponent from '../../components/Private/ModalComponent'

import { useNavigate } from 'react-router-dom'

export default function Home() {

  const navigateTo = useNavigate()
  const [user, setUser] = useState(null)
  const [userRole, setUserRole] = useState(null)
  const [applications, setApplications] = useState(
    {
      por_revisar: 0,
      pre_seleccionadas: 0,
      finalizadas: 0,
      destacadas: 0
    }
  )

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [showModalCallback, setShowModalCallback] = useState(false)
  const [modalTitleCallback, setModalTitleCallback] = useState('')
  const [loading, setLoading] = useState(null)

  let credentials = sessionUtils.getParsedCredentials()

  const handleUserLoad = async () => {
    const result = await userService.get(credentials.id)
    setUser(result)
  }

  const handleRoleLoad = async () => {
    const result = await roleService.get(credentials.role_id)
    setUserRole(result)
  }

  const handleApplicationsLoad = async () => {
    const result = await applicationService.list()

    let por_revisar = 0
    let pre_seleccionadas = 0
    let finalizadas = 0
    let destacadas = 0

    const XResult = Object.entries(result).map(([key, value]) => value)

    XResult.forEach((application) => {
      if (application.applicationStatusAfterDraft === 'En revisión') {
        por_revisar++
      }
      else
        if (application.applicationStatusPreselection === 'En revisión') {
          pre_seleccionadas++
        }
        else
          if (application.applicationStatusFinalist === 'En revisión') {
            finalizadas++
          }
          else
            if (application.applicationStatusFinal === 'Destacada') {
              destacadas++
            }
    })

    setApplications({
      por_revisar,
      pre_seleccionadas,
      finalizadas,
      destacadas
    })

    setLoading(false)

  }

  const handleModal = (title) => {
    setModalTitle(title)
    setShowModal(true)
  }

  const handleModalCallback = (title) => {
    setModalTitleCallback(title)
    setShowModalCallback(true)
  }

  const handleSentCeremonyNotification = async () => {
    const result = await notificationService.ceremonyNotification()
    if (result) {
      handleModalCallback('Invitación ceremonia')
    }
  }

  useEffect(() => {
    handleUserLoad()
    handleRoleLoad()
    setLoading(true)
    handleApplicationsLoad()
  }, [])

  useEffect(() => {
    console.log(loading)
  }, [loading])



  return (
    <>
      <Navbar />
      <main className="theme-light">
        <div className="container">
          {
            userRole && [1, 2, 3].includes(parseInt(userRole.id)) && (
              <>
                <div className="row row-cols-lg-4 row-cols-2 g-3 mb-lg-5 mb-4 text-center">
                  <div className="col">
                    <a
                      className='react-link text-decoration-none'
                      onClick={
                        () => {
                          navigateTo('/applications/enviadas')
                        }
                      }
                    >
                      <StatisticCard
                        classType='status-send'
                        title={applications.por_revisar}
                        text='Revisar'
                        loading={loading}
                      />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className='react-link text-decoration-none'
                      onClick={
                        () => {
                          navigateTo('/applications/preseleccion')
                        }
                      }
                    >
                      <StatisticCard
                        classType='status-draft'
                        title={applications.pre_seleccionadas}
                        text='Pre-seleccionadas'
                        loading={loading}
                      />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className='react-link text-decoration-none'
                      onClick={
                        () => {
                          navigateTo('/applications/finalistas')
                        }
                      }
                    >
                      <StatisticCard
                        classType='status-approved'
                        title={applications.finalizadas}
                        text='Finalizadas'
                        loading={loading}
                      />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className='react-link text-decoration-none'
                      onClick={
                        () => {
                          navigateTo('/applications/destacadas')
                        }
                      }
                    >
                      <StatisticCard
                        classType='status-featured'
                        title={applications.destacadas}
                        text='Destacadas'
                        loading={loading}
                      />
                    </a>
                  </div>
                </div>
                <div className="row row-cols-lg-3 row-cols-1 g-4 mb-5">
                  {
                    ![3].includes(parseInt(userRole.id)) && (
                      <div className="col">
                        <NavigationCard
                          icon={<i className="bi bi-people-fill card-icon"></i>}
                          title='Administrador de Usuarios de sistema'
                          route='/users'
                        />
                      </div>
                    )
                  }
                  <div className="col">
                    <NavigationCard
                      icon={<i className="bi bi-buildings card-icon"></i>}
                      title='Administrador de Empresas'
                      route='/companies'
                    />
                  </div>
                  {
                    ![3].includes(parseInt(userRole.id)) && (
                      <div className="col">
                        <NavigationCard
                          icon={<i className="bi bi-diagram-3-fill card-icon"></i>}
                          title='Administrador de Concursos'
                          route='/contests'
                        />
                      </div>
                    )
                  }
                  <div className="col">
                    <NavigationCard
                      icon={<i className="bi bi-folder-fill card-icon"></i>}
                      title='Postulaciones'
                      route='/applications/enviadas'
                    />
                  </div>
                  {
                    <div className="col">
                      <NavigationCard
                        icon={<i className="bi bi-ticket-detailed card-icon"></i>}
                        title='Tickets'
                        route='/tickets'
                      />
                    </div>
                  }
                  {
                    <div className="col">
                      <NavigationCard
                        icon={<i className="bi bi-cogs card-icon"></i>}
                        title='Notificaciones'
                        route='/configurations'
                      />
                    </div>
                  }
                  {/*
                    <div className="col">
                      <ModalComponent
                        id='alertModal'
                        title={modalTitle}
                        show={showModal}
                        setShow={setShowModal}
                      >
                        <p className='text-dark'>
                          ¿Está seguro que desea enviar la invitación a la ceremonia?
                        </p>
                        <div className='d-flex justify-content-end mt-4'>
                          <button
                            type='button'
                            className='btn btn-secondary me-2'
                            onClick={() => {
                              setShowModal(false)
                            }}
                          >
                            No
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary'
                            onClick={() => {
                              setShowModal(false)
                              handleSentCeremonyNotification()
                            }}
                          >
                            Sí
                          </button>
                        </div>
                      </ModalComponent>
                      <ModalComponent
                        id='alertModal2'
                        title={modalTitleCallback}
                        show={showModalCallback}
                        setShow={setShowModalCallback}
                      >
                        <p className='text-dark'>
                          Invitación enviada correctamente
                        </p>
                        <div className='d-flex justify-content-end mt-4'>
                          <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={() => {
                              setShowModalCallback(false)
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </ModalComponent>
                      <div className="card rounded no-shadow py-3">
                        <div className="card-body">
                          <i className="bi bi-award card-icon"></i>
                          <h4 className="h5 fw-medium my-4">
                            Invitación ceremonia
                          </h4>
                          <button className="btn btn-primary px-3 py-2 mb-3" type="button" onClick={
                            () => handleModal('Invitación ceremonia')
                          }>
                            Enviar
                          </button>
                        </div>
                      </div>
                    </div>
                        */}
                </div>
              </>
            )
          }
        </div>
      </main>
    </>
  )
}
